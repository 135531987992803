




import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {}
})
export default class CapitalRisePermissions extends Vue {
  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAutenti() {
    return this.activeUserData?.autenti
  }

  get isMarketplaceAdmin() {
    return this.activeUserData?.role?.marketplace === 'admin' ? true : false;
  }

  get isMarketplaceHead() {
    return this.activeUserData?.role?.marketplace === 'head' ? true : false;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get isPaymentManager() {
    return this.activeUserData.role?.paymentManager ? true : false;
  }

  get manageInvestorsEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if(this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('add-investor') ? true : false;
    }

    return false;
  }

  get addProductEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('add-product') ? true : false;
    }

    return false;
  }

  get manageProductsEnabled() {
    return (this.isMarketplaceAdmin || this.isMarketplaceHead) ? true : false;
  }

  get inviteInvestorsEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('invite-investor') ? true : false;
    }

    return false;
  }

  get fundPanelEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('access-to-fund-panel') ? true : false;
    }

    return false;
  }

  get brokerPanelEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('access-to-broker-panel') ? true : false;
    }

    return false;
  }
}
